<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom card-stretch gutter-b">
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label font-weight-bolder text-dark"
            >Datos del entrenamiento</span
            >
          </h3>
          <div class="card-toolbar">
            <a
                    @click="goList"
                    class="btn btn-secondary font-weight-bolder font-size-sm mr-3"
            >
              Volver
            </a>
          </div>
        </div>
        <div class="card-body pb-10 pb-2">
          <div class="row ml-2 mt-2">
            <div class="col-md-2">
              <img :src="selectedItem.image" class="image-view" alt=""/>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-12">
                  <div class="label-detail">Nombre</div>
                  <div>{{ selectedItem.name }}</div>
                </div>
                <div class="col-md-12">
                  <div class="label-detail">Descripción</div>
                  <div>{{ selectedItem.description }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row ml-2 mt-7">
            <div class="col-md-12" data-app>
              <b-form-group
                      id="input-group-description"
                      label-for="input-2"
              ><template v-slot:label>
                <h4>Listado de los cursos</h4>
              </template>
                <v-text-field
                        v-model="dtSearch"
                        label="Buscar"
                        append-icon="search"
                        class="mx-4"
                        single-line
                        hide-details
                ></v-text-field>
                <v-data-table
                        :headers="headers"
                        :items="courseList"
                        item-key="id"
                        :loading="isLoadingTable"
                        :search="dtSearch"
                        :footer-props="{
                            itemsPerPageOptions: [3, 5, 10, 25, 50],
                            showFirstLastPage: true,
                            itemsPerPageText: 'Resultados por página'
                        }"
                        :items-per-page="itemsPerPage"
                        class="elevation-1"
                        locale="es"
                >
                  <template v-slot:item.image="{ item }">
                    <div class="">
                      <img
                              :src="item.image"
                              class="image-table medium align-self-start"
                              alt=""
                      />
                    </div>
                  </template>
                  <template slot="no-data">
                    Ningún dato para mostrar
                  </template
                  >
                </v-data-table>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrainingsDetail",
  data() {
    return {
      isLoading: false,
      isLoadingTable: false,
      options: {},
      currentPage: 1,
      itemsPerPage: 10,
      headers: [
        { text: "Imagen", value: "image", align: "start", sortable: false, width: 100 },
        { text: "Nombre", value: "name", align: "start", sortable: false, width: 250 },
        { text: "Descripción", value: "description", sortable: false },
      ],
      dtSearch: ""
    };
  },
  methods:{
    goList() {
      this.$router.push("trainings");
    },
  },
  computed: {
    courseList() {
      return this.selectedItem.courses;
    },
    selectedItem() {
      return this.$store.state.trainings.selectedTraining;
    },
  },
};
</script>

<style>
  .v-data-footer__select .v-select{
    padding: 15px!important;
  }
</style>
